<template>
  <div>
    <div class="mt-3 p-2">
      <b-container fluid class="mb-2">
        <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>
        <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                id="input-group-2"
                label="Select Activity type :"
                label-for="input-2"
              >
                <b-form-select
                  v-model="form._activityTypeID"
                  :options="activitiesType"
                  value-field="_id"
                  text-field="name"
                  disabled
                >
                  <template #first>
                    <b-form-select-option value="" disabled
                      >Select Activity Category</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="input-2"
                  v-model="form.description"
                  placeholder="Enter description..."
                  rows="5"
                  max-rows="8"
                ></b-form-textarea>
              </b-form-group>
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-1"
                    label="Start Date:"
                    label-for="input-1"
                  >
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="form.start_date"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    id="input-group-1"
                    label="End Date:"
                    label-for="input-1"
                  >
                    <b-form-datepicker
                      id="example-datepicker1"
                      v-model="form.end_date"
                      class="mb-2"
                    ></b-form-datepicker>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-1"
                    label="Status:"
                    label-for="input-1"
                  >
                    <select
                      v-model="form.activity_status"
                      required="required"
                      class="form-control"
                    >
                      <option value="">Select Activity status</option>
                      <option value="1">Pending</option>
                      <option value="2">In Progress</option>
                      <option value="3">Completed</option>
                    </select>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="Progress:"
                    label-for="input-2"
                  >
                    <select
                      v-model="form.progress"
                      required="required"
                      class="form-control"
                    >
                      <option value="">Select Activity status</option>
                      <option value="0">0%</option>

                      <option value="25">25%</option>
                      <option value="50">50%</option>
                      <option value="75">75%</option>
                      <option value="100">100%</option>
                    </select>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-1"
                    label="Frequency:"
                    label-for="input-1"
                  >
                    <select
                      v-model="form.frequency"
                      required="required"
                      class="form-control"
                    >
                      <option value="">Select Frequency</option>
                      <option value="Daily">Daily</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Yearly">Yearly</option>
                      <option value="Half-yearly">Half-yearly</option>
                      <option value="Quarter-yearly">Quarter-yearly</option>
                      <option value="On need basis">On need basis</option>
                    </select>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group
                    id="input-group-2"
                    label="No of Workers:"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="form.no_of_worker"
                      placeholder="Enter No of Workers"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <b-form-group
                id="input-group-2"
                label="Resource:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.resources"
                  placeholder="Enter Resource"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Image:"
                label-for="input-2"
              >
                <b-form-file
                  id="file"
                  ref="file"
                  v-model="form.image"
                  :state="Boolean(form.image)"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="uploadImage"
                ></b-form-file>
              </b-form-group>
              <img
                :src="form.image"
                class="img-fluid"
                alt="activity image"
                height="300px"
                width="400px"
              />
            </div>
            <div class="col-md-12">
              <b-button size="sm" variant="success" @click="submitForm()">
                Update
              </b-button>
            </div>
          </div>
        </b-form>
      </b-container>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        _activityTypeID: '',
        description: '',
        start_date: '',
        end_date: '',
        frequency: '',
        no_of_worker: '',
        resources: '',
        image: '',
        assignment_level: '',
        _project_id: '',
        _zone_id: '',
        _plot_id: '',
        notification: true,
        visible: true,
        activity_status: '',
        progress: '',
      },

      projects: [],
      zones: [],
      plots: [],

      success: false,
    };
  },

  mounted() {
    this.setupdata();
  },
  methods: {
    setupdata() {
      let activityID = this.$route.params.activity_id;
      window.axios.get('/activity/' + activityID).then((resp) => {
        console.log(resp.data.success);
        if (resp.data.success) {
          this.form._activityTypeID = resp.data.data._activityTypeID._id;
          this.form.description = resp.data.data.description;
          this.form.start_date = resp.data.data.start_date;
          this.form.end_date = resp.data.data.end_date;
          this.form.frequency = resp.data.data.frequency;
          this.form.no_of_worker = resp.data.data.no_of_worker;
          this.form.resources = resp.data.data.resources;
          this.form.image = resp.data.data.image;
          this.form._id = resp.data.data._id;
          this.form.activity_status = resp.data.data.progress_status;
          this.form.progress = resp.data.data.progress;
        }
      });
    },

    uploadImage() {
      this.form.image = this.$refs.file.files[0];
    },

    submitForm() {
      let formData = new FormData();
      formData.append('description', this.form.description);
      formData.append('start_date', this.form.start_date);
      formData.append('end_date', this.form.end_date);
      formData.append('frequency', this.form.frequency);
      formData.append('no_of_worker', this.form.no_of_worker);
      formData.append('resources', this.form.resources);
      formData.append('image', this.form.image);
      formData.append('activity_status', this.form.activity_status);
      formData.append('progress', this.form.progress);
      formData.append('_id', this.form._id);
      window
        .axios({
          method: 'PUT',
          url: 'activity/' + this.form._id,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((resp) => {
          if (resp.data.success) {
            this.success = resp.data.message;
            console.log(resp.data.success);
            this.$emit('create', resp.data);
            setTimeout(
              () => ((this.success = false), this.$router.push('/activities')),
              3000
            );
          }
        });
    },
  },
};
</script>
